import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const HeroHeader = props => (
  
    <section className={`hero ${props.isGradient} is-large is-relative`}>
      {props.fluid && (
        <Img
          fluid={props.fluid}
          style={{
            position: "absolute",
            overflow: "hidden",
          }}
          imgStyle={{
            width: "100%",
            height: "100%",
            opacity: "0.2",
          }}
          className="image-wrapper-height-auto"
          alt={props.alt}
        />
      )}

      {props.animatedIllustration && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            overflow: "hidden",
            bottom: 0,
            top: 0,
            height: "100%",
            width: "100%",
          }}
        >
          {props.animatedIllustration}
        </div>
      )}

      <div className="hero-body" style={{ zIndex: 5 }}>
        <div className="container">
          <div className="content is-large">
            <div className="columns">
              <div className="column is-6">
                <div className="content primaryblockquote">
                  <h1 className="title is-1 is-white">{props.headerTitle}</h1>
                  <h2 className="title is-5">{props.headerSubtitle}</h2>
                </div>
              </div>
              <div className="column is-6">
                <Img fluid={props.rightImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
)

HeroHeader.propTypes = {
  isGradient: PropTypes.string,
}

HeroHeader.defaultProps = {
  isGradient: "is-gradient",
}

export default HeroHeader
